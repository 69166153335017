import { CommonModule } from "@angular/common";
import { Component, ElementRef, forwardRef, Input, OnInit, ViewChild } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR, ReactiveFormsModule, UntypedFormControl } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";

@Component({
  selector: "app-time-input",
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, MatIconModule],
  templateUrl: "./time-input.component.html",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimeInputComponent),
      multi: true
    }
  ],
  styles: []
})
export class TimeInputComponent implements OnInit, ControlValueAccessor {
  inputValue: string;
  initialValue: string = "";
  private previousValue: string;
  @Input() formControl: UntypedFormControl;
  @Input() readonly: boolean;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() required: boolean;

  constructor(private eRef: ElementRef) {}
  @ViewChild("inputElement") inputElement: ElementRef;

  focus() {
    this.inputElement.nativeElement.focus();
  }

  onChange: any = value => {
    this.inputValue = value;
  };
  onTouch: any = () => {};

  set value(val) {
    this.inputValue = val;
    this.onChange(val);
  }
  get value() {
    return this.inputValue;
  }

  writeValue(value: any): void {
    if (value) this.value = value;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  private _resetValue() {
    this.formControl.setValue(this.previousValue || this.initialValue);
  }

  private _setValue(value) {
    this.formControl.setValue(value);
    this.previousValue = value;
  }

  updateInput(val) {
    //WTF border case if someones enters 1,47
    const number = /^[0-9]+$/;
    if (!val.match(number)) {
      return this.previousValue || this.initialValue;
    }

    return this.handleBlur(
      {
        target: {
          value: val
        }
      },
      true
    );
  }

  handleBlur(e, dontUpdate?) {
    if (e.target.value === "") return;

    const leftValue = e.target.value.split(":")[0];
    let value = e.target.value.replace(":", "");

    const zeroToFour = /^[0-4]+$/;
    const zeroToThree = /^[0-3]+$/;
    const zeroToFive = /^[0-5]+$/;
    const greaterThanTwo = /^[3-9]+$/;
    const number = /^[0-9]+$/;

    //Add a 0 to the value if the hours have only one digit
    if (leftValue.length === 1) {
      value = "0" + value;
    }

    const splited = value.split("");
    const firstNumber = splited[0];
    const secondNumber = splited[1];
    const thirdNumber = splited[2];
    const fourthNumber = splited[3];

    //========= ☞ Don't try to trick me! ⚠︎  ============
    //Not a number? 'This morning' is not a time
    if (!value.match(/^\d/)) {
      return dontUpdate ? (this.previousValue || this.initialValue) : this._resetValue();
    }
    //Input must be a number, not like 1,32
    if (!value.match(number)) {
      return dontUpdate ? (this.previousValue || this.initialValue) : this._resetValue();
    }
    // More than 4 digits? We are not asking for minutes.
    if (value.length > 4) {
      return dontUpdate ? (this.previousValue || this.initialValue) : this._resetValue();
    }
    //More than 60 minutes in an hour... go back to first grade.
    if (thirdNumber && !thirdNumber.match(zeroToFive)) {
      return dontUpdate ? (this.previousValue || this.initialValue) : this._resetValue();
    }
    // 34 hours? no, try again
    if (secondNumber && firstNumber.match(greaterThanTwo)) {
      return dontUpdate ? (this.previousValue || this.initialValue) : this._resetValue();
    }
    // Day with more than 24 hours? Really?
    if (firstNumber === "2" && secondNumber && !secondNumber.match(zeroToThree)) {
      return dontUpdate ? (this.previousValue || this.initialValue) : this._resetValue();
    }

    // ================================================

    // ============= Set values if input is correct. Good, you did it ★★★★★
    //one digit
    if (value.length === 1) {
      let val = "0" + value + "00";
      if (dontUpdate) return val;

      this._setValue(val);
    }

    //two digits
    if (value.length === 2) {
      let val = value + "00";
      if (dontUpdate) return val;

      this._setValue(val);
    }

    //3 digits
    if (value.length === 3) {
      let val = firstNumber + secondNumber + "" + thirdNumber + "0";
      if (dontUpdate) return val;

      this._setValue(val);
    }

    //4 digits
    if (value.length === 4) {
      let val = firstNumber + secondNumber + "" + thirdNumber + fourthNumber;
      if (dontUpdate) return val;

      this._setValue(val);
    }
    //================================================
  }

  ngOnInit(): void {
    this.initialValue = this.formControl?.value || "";
  }
}
