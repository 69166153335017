<ng-container *ngIf="label">
  <label 
    class="base-input-label w-full"
    [ngClass]="{ 'opacity-50': formControl?.disabled }"
  >
    {{ label }} {{ required && "*" }}
    <input
      #inputElement
      type="text"
      class="base-text-input"
      placeholder="{{ placeholder }}"
      (focus)="$event.target.select()"
      (blur)="handleBlur($event)"
      [formControl]="formControl"
      [ngClass]="{ 'background-grey': formControl?.disabled }"
      *ngIf="!readonly"
    />
    <span *ngIf="readonly">{{ formControl.value }}</span>
  </label>
</ng-container>
<ng-container *ngIf="!label">
  <input
    #inputElement
    type="text"
    class="row-input w-16"
    (focus)="$event.target.select()"
    (blur)="handleBlur($event)"
    [formControl]="formControl"
    *ngIf="!readonly"
  />
  <span *ngIf="readonly">{{ formControl.value }}</span>
</ng-container>
